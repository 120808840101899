
  import { defineComponent, ref, inject } from "vue";
  import fixedHeader from "@/components/common/Header.vue";
  import fixedFooter from "@/components/common/Footer.vue";
  import axios from "@/api/axios";
  import Toast from "@/components/UI/Toast";
  import router from "@/router";

  export default defineComponent({
    name: "Lottery",
    components: {
      fixedHeader,
      fixedFooter,
    },
    setup(){
      const arr = [1,2,3,4,5,6,7,8];
      let i = 0;//转到哪个位置
      let count= 0;//转圈初始值
      const totalCount = 3;//转动的总圈数
      let speed = 300;//转圈速度，越大越慢
      const minSpeed = 300;
      let timer: any = null;
      let isClick = false;
      let index = 3;//指定转到哪个奖品
      const text = ref("668积分");//奖品
      const activeNum = ref(0);
      const imgUrl = ref("https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png");
      const imgStyle = ref({
        width: "1rem",
        left: "1.46rem",
        top: "1.5rem",
      });
      const wechatShow = ref(false);
      const imgUrlArr = [
        {
          img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
          text: "668积分"
        },
        {
          img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
          text: "5888积分"
        },
        {
          img:  "https://beinabncc.oss-cn-beijing.aliyuncs.com/News/d9b2a89dfe154eaee25d72078f7a3838.png",
          text: "华为手机"
        },
        {
          img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
          text: "2888积分"
        },
        {
          img: "https://beinabncc.oss-cn-beijing.aliyuncs.com/News/1f4d59ebde1f89f298670296193d00d7.png",
          text: "笔记本电脑"
        },
        {
          img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
          text: "666积分"
        },
        {
          img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
          text: "888积分"
        },
        {
          img: "https://beinabncc.oss-cn-beijing.aliyuncs.com/News/12c5f1597d5bc6d8033092259d3c449e.png",
          text: "扫地机器人"
        },
      ]
      const imgArr = [
        {
          img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
          width: "0.7rem",
          left: "2.3rem",
          top: "1.9rem",
        },
        {
          img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
          width: "0.7rem",
          left: "2.3rem",
          top: "1.9rem",
        },
        {
          img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
          width: "0.7rem",
          left: "2.3rem",
          top: "1.9rem",
        },
        {
          img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
          width: "0.7rem",
          left: "2.3rem",
          top: "1.9rem",
        }
      ]
      const dialogShow = ref(false);
      function roll(){
        //速度衰减
        speed -= 50;
        if(speed<=10){
          speed=10;
        }
        //每次调用都去掉全部active类名
        activeNum.value = 10;
        i++;
        //计算转圈次数
        if(i >= 8){
          i = 0;
          count++;
        }
        activeNum.value = i;
        //满足转圈数和指定位置就停止
        if (count >= totalCount && (i + 1) == index){
          clearTimeout(timer);
          speed = minSpeed;
          text.value = imgUrlArr[i].text;
          imgUrl.value = imgUrlArr[i].img;
          isClick = true;
          for (let j = 0; j < imgArr.length; j++) {
            if (imgArr[j].img == imgUrl.value) {
              imgStyle.value = {
                width: imgArr[j].width,
                left: imgArr[j].left,
                top: imgArr[j].top,
              }
            }
          }
          setTimeout(function () {
            dialogShow.value = true;
          }, 1200)
        }else{
          timer = setTimeout(roll,speed);//不满足条件时调用定时器
          //最后一圈减速
          if(count >= totalCount-1 || speed <= 50){
            speed += 100;
          }
        }
      }


      function handleDialog(){
        dialogShow.value = false;
      }
      function handleLuckDraw() {
        const formData = new FormData();
        formData.append('id', '10');
        axios
                .post("/M/Event/WheelOfFortune", formData)
                .then((res) => {
                  const data = res.data.obj;
                  if (res.data.success) {
                    index = data;
                    roll();
                    text.value = res.data.msg;
                  } else if(res.data.msg=="请先登录再执行操作") {
                    Toast({
                      title: res.data.msg,
                      type: "error",
                      duration: 1000,
                    });
                    router.push({
                      path: "Login",
                      query: {
                        redirect: router.currentRoute.value.fullPath,
                      },
                    });
                  }
                  else{
                    Toast({
                      title: res.data.msg,
                      type: "error",
                      duration: 2000
                    });
                  }
                })
                .catch(() => {
                  // Toast({
                  //     title: '未知错误，请联系客服',
                  //     type: "success"
                  // })
                });
        // index = Math.floor(Math.random()*arr.length+1);
        // roll();
        // text.value = '100元代金券';
      }

      function handleWechatShow(){
        wechatShow.value = false;
      }

      return {
        text,
        imgUrl,
        handleLuckDraw,
        imgStyle,
        activeNum,
        dialogShow,
        handleDialog,
        handleWechatShow,
        wechatShow
      }
    }
  });
