<template>
  <fixed-header :isHideBack="$route.meta.hideBack"></fixed-header>

  <div class="wheelOfFortune_wrap">
    <h1>恭喜您获得大抽奖机会</h1>
    <div class="wechatDraw_decription">
      <p>超高积分免费抽</p>
      <p>华为手机等你兑</p>
      <p>赶快行动吧！</p>
    </div>
    <div class="wheelOfFortune_middle">
      <ul class="lottery">
        <li :class="{'wheelOfFortune_li_redpacket': true, 'active': activeNum == 0}">
          <img
                  src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png"
                  alt="668积分"
          />
          <div>668积分</div>
        </li>
        <li :class="{'wheelOfFortune_li_redpacket': true, 'active': activeNum == 1}">
          <img
                  src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png"
                  alt="5888积分"
          />
          <div>
            5888积分
          </div>
        </li>
        <li :class="{'wheelOfFortune_li_redpacket': true, 'active': activeNum == 2}">
          <img
                  src="https://beinabncc.oss-cn-beijing.aliyuncs.com/News/d9b2a89dfe154eaee25d72078f7a3838.png"
                  alt="华为手机"
          />
          <div>华为手机</div>
        </li>
        <li :class="{'wheelOfFortune_li_redpacket': true, 'active': activeNum == 3}">
          <img
                  src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png"
                  alt="2888积分"
          />
          <div>
            2888积分
          </div>
        </li>
        <li :class="{'wheelOfFortune_li_redpacket': true, 'active': activeNum == 4}">
          <img
                  src="https://beinabncc.oss-cn-beijing.aliyuncs.com/News/1f4d59ebde1f89f298670296193d00d7.png"
                  alt="笔记本电脑"
          />
          <div>笔记本电脑</div>
        </li>
        <li :class="{'wheelOfFortune_li_redpacket': true, 'active': activeNum == 5}">
          <img
                  src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png"
                  alt="666积分"
          />
          <div>
            666积分
          </div>
        </li>
        <li :class="{'wheelOfFortune_li_redpacket': true, 'active': activeNum == 6}">
          <img
                  src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png"
                  alt="888积分"
          />
          <div>888积分</div>
        </li>
        <li :class="{'wheelOfFortune_li_redpacket': true, 'active': activeNum == 7}">
          <img
                  src="https://beinabncc.oss-cn-beijing.aliyuncs.com/News/12c5f1597d5bc6d8033092259d3c449e.png"
                  alt="扫地机器人"
          />
          <div>
            扫地机器人
          </div>
        </li>
        <li class="wheelOfFortune_li_luckDraw" @click="handleLuckDraw">
          <img
                  src="@/assets/img/whellOfFortune/luckDraw.png"
                  alt="马上抽奖"
          />
          <div>马上抽奖</div>
        </li>
      </ul>
    </div>
    <div class="wechatDraw_share">
      <p>好东西当然要和好朋友分享</p>
      <p>自己拿礼品的同时</p>
      <p>记得分享给朋友哦</p>
    </div>
    <div class="wechatDraw_bottom">本活动最终解释权归北纳生物所有</div>



    <div class="wheelOfFortune_dialog" v-show="dialogShow">
      <div class="wheelOfFortune_dialog_yy" @click="handleDialog"></div>
      <div class="wheelOfFortune_dialog_content">
        <span class="wheelOfFortune_dialog_span"></span>
        <img
                :src="imgUrl"
                class="prize"
                alt="加微信抢红包-奖品图片"
                :style="imgStyle"
        />
        <div class="wheelOfFortune_dialog_bottom" @click="handleDialog">
          <p>恭喜您抽中了<label class="prize_text">{{ text }}</label></p>
          <span class="wheelOfFortune_dialog_btn"><a href="/intergral">立即领取</a></span>
        </div>
      </div>
    </div>
  </div>
  <fixed-footer :isHideBack="$route.meta.hideFooter"></fixed-footer>

</template>

<script lang="ts">
  import { defineComponent, ref, inject } from "vue";
  import fixedHeader from "@/components/common/Header.vue";
  import fixedFooter from "@/components/common/Footer.vue";
  import axios from "@/api/axios";
  import Toast from "@/components/UI/Toast";
  import router from "@/router";

  export default defineComponent({
    name: "Lottery",
    components: {
      fixedHeader,
      fixedFooter,
    },
    setup(){
      const arr = [1,2,3,4,5,6,7,8];
      let i = 0;//转到哪个位置
      let count= 0;//转圈初始值
      const totalCount = 3;//转动的总圈数
      let speed = 300;//转圈速度，越大越慢
      const minSpeed = 300;
      let timer: any = null;
      let isClick = false;
      let index = 3;//指定转到哪个奖品
      const text = ref("668积分");//奖品
      const activeNum = ref(0);
      const imgUrl = ref("https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png");
      const imgStyle = ref({
        width: "1rem",
        left: "1.46rem",
        top: "1.5rem",
      });
      const wechatShow = ref(false);
      const imgUrlArr = [
        {
          img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
          text: "668积分"
        },
        {
          img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
          text: "5888积分"
        },
        {
          img:  "https://beinabncc.oss-cn-beijing.aliyuncs.com/News/d9b2a89dfe154eaee25d72078f7a3838.png",
          text: "华为手机"
        },
        {
          img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
          text: "2888积分"
        },
        {
          img: "https://beinabncc.oss-cn-beijing.aliyuncs.com/News/1f4d59ebde1f89f298670296193d00d7.png",
          text: "笔记本电脑"
        },
        {
          img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
          text: "666积分"
        },
        {
          img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
          text: "888积分"
        },
        {
          img: "https://beinabncc.oss-cn-beijing.aliyuncs.com/News/12c5f1597d5bc6d8033092259d3c449e.png",
          text: "扫地机器人"
        },
      ]
      const imgArr = [
        {
          img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
          width: "0.7rem",
          left: "2.3rem",
          top: "1.9rem",
        },
        {
          img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
          width: "0.7rem",
          left: "2.3rem",
          top: "1.9rem",
        },
        {
          img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
          width: "0.7rem",
          left: "2.3rem",
          top: "1.9rem",
        },
        {
          img: "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Zhengshu/integral20210113.png",
          width: "0.7rem",
          left: "2.3rem",
          top: "1.9rem",
        }
      ]
      const dialogShow = ref(false);
      function roll(){
        //速度衰减
        speed -= 50;
        if(speed<=10){
          speed=10;
        }
        //每次调用都去掉全部active类名
        activeNum.value = 10;
        i++;
        //计算转圈次数
        if(i >= 8){
          i = 0;
          count++;
        }
        activeNum.value = i;
        //满足转圈数和指定位置就停止
        if (count >= totalCount && (i + 1) == index){
          clearTimeout(timer);
          speed = minSpeed;
          text.value = imgUrlArr[i].text;
          imgUrl.value = imgUrlArr[i].img;
          isClick = true;
          for (let j = 0; j < imgArr.length; j++) {
            if (imgArr[j].img == imgUrl.value) {
              imgStyle.value = {
                width: imgArr[j].width,
                left: imgArr[j].left,
                top: imgArr[j].top,
              }
            }
          }
          setTimeout(function () {
            dialogShow.value = true;
          }, 1200)
        }else{
          timer = setTimeout(roll,speed);//不满足条件时调用定时器
          //最后一圈减速
          if(count >= totalCount-1 || speed <= 50){
            speed += 100;
          }
        }
      }


      function handleDialog(){
        dialogShow.value = false;
      }
      function handleLuckDraw() {
        const formData = new FormData();
        formData.append('id', '10');
        axios
                .post("/M/Event/WheelOfFortune", formData)
                .then((res) => {
                  const data = res.data.obj;
                  if (res.data.success) {
                    index = data;
                    roll();
                    text.value = res.data.msg;
                  } else if(res.data.msg=="请先登录再执行操作") {
                    Toast({
                      title: res.data.msg,
                      type: "error",
                      duration: 1000,
                    });
                    router.push({
                      path: "Login",
                      query: {
                        redirect: router.currentRoute.value.fullPath,
                      },
                    });
                  }
                  else{
                    Toast({
                      title: res.data.msg,
                      type: "error",
                      duration: 2000
                    });
                  }
                })
                .catch(() => {
                  // Toast({
                  //     title: '未知错误，请联系客服',
                  //     type: "success"
                  // })
                });
        // index = Math.floor(Math.random()*arr.length+1);
        // roll();
        // text.value = '100元代金券';
      }

      function handleWechatShow(){
        wechatShow.value = false;
      }

      return {
        text,
        imgUrl,
        handleLuckDraw,
        imgStyle,
        activeNum,
        dialogShow,
        handleDialog,
        handleWechatShow,
        wechatShow
      }
    }
  });
</script>

<style lang="scss" scoped>
  .wheelOfFortune_wrap h1 {
    text-align: center;
    line-height: 30px;
    font-size: 20px;
    color:#666;
  }
  .wechatDraw_decription, .wechatDraw_share {
    font-size: 16px;
    text-align: center;
    line-height: 30px;
  }
  .wechatDraw_share {
    margin-top: 20px;
  }
  .wechatDraw_bottom {
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    color: #333;
    margin-top: 20px;
  }
  .wheelOfFortune_wrap{
    padding-top: 55px;
    img{
      vertical-align: middle;
    }
  }
  .wheelOfFortune_wrap {
    width: 100%;
    height: 100%;

    position: relative;
  }

  .wheelOfFortune_logo {
    text-align: center;
    padding-top: 17px;
  }

  .wheelOfFortune_logo img {
    width: 92px;
  }

  .wheelOfFortune_title {
    text-align: center;
    margin-top: 10px;
  }

  .wheelOfFortune_title img {
    width: 182px;
  }

  .wheelOfFortune_middle {
    width: 261px;
    height: 221.5px;
    background: url("../assets/img/whellOfFortune/middleBg.png") no-repeat;
    background-size: 100%;
    margin: 0 auto;
    margin-top: 20px;
    box-sizing: content-box;
  }

  .wheelOfFortune_middle ul {
    width: 100%;
    height: 100%;
    padding: 13px 12.5px;
    position: relative;
  }

  .wheelOfFortune_middle ul li {
    width: 76px;
    height: 63px;
    border-radius: 10px;
    display: inline-block;
    text-align: center;
    background: url("../assets/img/whellOfFortune/cashBtn.png") no-repeat;
    background-size: cover;
    box-sizing: border-box;
    float: left;
    margin: 1px;
  }

  .wheelOfFortune_middle ul li p {
    -webkit-transform-origin-x: 0;
    -webkit-transform: scale(0.8);
    width: 125%;
    line-height: 12.5px;
    font-size: 12px;
  }

  // .wheelOfFortune_li_cash {
  //   padding-top: 5px;
  // }

  .wheelOfFortune_li_cash img {
    width: 44px;
    height: 19.5px;
  }

  .wheelOfFortune_li_cash div {
    margin-top: -2px;
  }

  .wheelOfFortune_li_cash div,
  .wheelOfFortune_li_redpacket div,
  .wheelOfFortune_li_integral div {
    color: rgb(167, 34, 5);
    font-weight: bold;
    -webkit-transform-origin-x: 0;
    -webkit-transform: scale(0.8);
    width: 125%;
    line-height: 12.5px;
    font-size: 11px;
  }

  .wheelOfFortune_li_redpacket div{
    margin-top: -2px;
  }

  .wheelOfFortune_li_luckDraw {
    position: relative;
  }

  .wheelOfFortune_li_luckDraw div {
    position: absolute;
    font-size: 20px;
    color: rgb(233, 20, 12);
    font-weight: bold;
    width: 60%;
    top: 5%;
    left: 21%;
    line-height: 25px;
  }

  .wheelOfFortune_li_redpacket img {
    width: 27.5px;
    height: 25.5px;
  }

  // .wheelOfFortune_li_integral {
  //   padding-top: 7px;
  // }

  .wheelOfFortune_li_integral div {
    margin-top: 3px;
  }

  .wheelOfFortune_li_integral img {
    width: 31.5px;
  }

  .wheelOfFortune_middle li.active {
    border: 4px solid #45fb27;
  }

  .wheelOfFortune_bottom {
    margin-top: 20px;
    padding-bottom: 75px;
  }

  /*.wheelOfFortune_bottom_line {*/
  /*  width: 300px;*/
  /*  height: 5.5px;*/
  /*  background: rgb(234, 33, 7);*/
  /*  border-radius: 10px;*/
  /*  border: 1px solid rgb(234, 104, 162);*/
  /*  margin: 0 auto;*/
  /*}*/

  .wheelOfFortune_bottom_wrap {
    width: 280px;
    margin: 0 auto;
  }

  .wheelOfFortune_bottom_title {
    width: 100%;
    height: 20px;
    background: rgb(246, 98, 89);
    color: rgb(248, 222, 30);
    font-size: 15px;
    text-align: center;
  }

  .wheelOfFortune_bottom_content {
    width: 100%;
    padding: 10px 0;
    color: #fff;
    font-size: 14px;
  }

  .wheelOfFortune_bottom_img img {
    width: 100%;
  }

  .wheelOfFortune_middle ul li:nth-child(1) {
    position: absolute;
    display: block;
    left: 13px;
    top: 12.5px;
  }

  .wheelOfFortune_middle ul li:nth-child(2) {
    position: absolute;
    left: 91px;
    top: 12.5px;
  }

  .wheelOfFortune_middle ul li:nth-child(3) {
    position: absolute;
    left: 169px;
    top: 12.5px;
  }

  .wheelOfFortune_middle ul li:nth-child(4) {
    position: absolute;
    left: 169px;
    top: 77.5px;
  }

  .wheelOfFortune_middle ul li:nth-child(8) {
    position: absolute;
    left: 13px;
    top: 77.5px;
  }

  .wheelOfFortune_middle ul li:nth-child(7) {
    position: absolute;
    left: 13px;
    top: 142.5px;
  }

  .wheelOfFortune_middle ul li:nth-child(6) {
    position: absolute;
    left: 91px;
    top: 142.5px;
  }

  .wheelOfFortune_middle ul li:nth-child(5) {
    position: absolute;
    left: 169px;
    top: 142.5px;
  }

  .wheelOfFortune_middle ul li:nth-child(9) {
    position: absolute;
    left: 91px;
    top: 77.5px;
    img{
      max-width: 100%;
    }
  }

  .wheelOfFortune_bottom_img {
    position: fixed;
    bottom: 0;
    left: 0;
  }


  .wheelOfFortune_dialog {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .wheelOfFortune_dialog_content {
    width: 194px;
    height: 270px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -97px;
    margin-top: -175px;
    background: url(../assets/img/whellOfFortune/dialog.png) no-repeat;
    background-size: contain;
    background-color: #fff;
    border-radius: 10px;
  }

  .wheelOfFortune_dialog_yy,
  .wheelOfFortune_wechat_yy {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  .prize {
    position: absolute;
    width: 50px;
    left: 73px;
    top: 75px;
  }

  .wheelOfFortune_dialog_bottom {
    margin-top: 160px;
    text-align: center;
  }

  .wheelOfFortune_dialog_bottom p {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    padding: 0 10px;
  }

  .wheelOfFortune_dialog_bottom span {
    display: inline-block;
    width: 119px;
    height: 24px;
    line-height: 24px;
    border-radius: 12px;
    margin-top: 15px;
    background: rgb(255, 242, 59);
    box-shadow: 0px 3px 0px rgba(255, 169, 57, 1);
    -moz-box-shadow: 0px 3px 0px rgba(255, 169, 57, 1);
    -webkit-box-shadow: 0px 3px 0px rgba(255, 169, 57, 1);
    background: linear-gradient(to bottom, rgb(255, 249, 69), rgb(255, 198, 0));
    background: -moz-linear-gradient(to bottom, rgb(255, 249, 69), rgb(255, 198, 0));
    background: -webkit-linear-gradient(to bottom, rgb(255, 249, 69), rgb(255, 198, 0));
    color: rgb(255, 78, 0);
    font-weight: bold;
    font-size: 12px;
  }

  .wheelOfFortune_dialog_span {
    display: inline-block;
    width: 50%;
    height: 20px;
    position: absolute;
    top: 117.5px;
    left: 50px;
    background: rgb(255, 63, 49);
  }

  .wheelOfFortune_wechat {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .wheelOfFortune_wechat_title {
    font-size: 20px;
    color: #333;
  }

  .wheelOfFortune_wechat_title p {
    text-align: center;
  }

  .wheelOfFortune_wechat_content {
    width: 80%;
    background: #fff;
    position: absolute;
    text-align: center;
    padding: 15px;
    left: 10%;
    top: 20%;
  }

  .wheelOfFortune_wechat_content img {
    width: 250px;
  }

  .wheelOfFortune_wechat_active {
    display: none;
  }
</style>
